import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import google from "../../images/google.png";
import { firebase, provider } from "../../firebase";
import axios from "../../utils/axios";

import "./Login.css";

class Login extends Component {
  render() {
    return (
      <div>
        <div id="sign-in-button">
          <img src={google} className="" alt="google" />
          <Button
            onClick={this.signInWithGoogle}
            variant="light"
            className="google-signin"
          >
            Sign in with Google
          </Button>
        </div>
      </div>
    );
  }

  signInWithGoogle = async () => {
    const response = await firebase.auth().signInWithPopup(provider);
    const [firstName, lastName] = response.user.displayName.split(" ");
    const body = {
      id: response.user.uid.toString(),
      email: response.user.email,
      firstName,
      lastName,
    };
    axios.post("api/login", JSON.stringify(body), {
      headers: { "Content-Type": "application/json" },
    });

    this.props.history.push("/");
  };
}

export default withRouter(Login);
