import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { firebase } from "../../firebase";
import { logoutUser } from "../../actions";

const mapStateToProps = ({ user }) => ({
  user,
});
const mapDispatchToProps = {
  logoutUser,
};

class Profile extends Component {
  render() {
    return (
      <Container>
        <div id="log-out-button">
          <Button onClick={this.signOutWithGoogle}>Log out</Button>
        </div>
      </Container>
    );
  }

  signOutWithGoogle = async () => {
    await firebase.auth().signOut();
    this.props.logoutUser();
    this.props.history.push("/");
  };
}

export default Profile = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
