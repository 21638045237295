import React, { Component } from "react";
import { Button, Container, Form, FormGroup, FormLabel } from "react-bootstrap";

// import axios from "../../utils/axios";

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.password = React.createRef();
    this.firstName = React.createRef();
    this.lastName = React.createRef();
  }

  render() {
    return (
      <Container>
        <Form onSubmit={this.onSignup}>
          <FormGroup>
            <FormLabel>Email:</FormLabel>
            <Form.Control type="text" ref={this.email} />
          </FormGroup>
          <FormGroup>
            <FormLabel>Password:</FormLabel>
            <Form.Control type="password" ref={this.password} />
          </FormGroup>
          <FormGroup>
            <FormLabel>First Name:</FormLabel>
            <Form.Control type="text" ref={this.firstName} />
          </FormGroup>
          <FormGroup>
            <FormLabel>Last Name:</FormLabel>
            <Form.Control type="text" ref={this.lastName} />
          </FormGroup>
          <Button variant="primary" type="submit">
            Sign Up
          </Button>
        </Form>
      </Container>
    );
  }

  onSignup = async (event) => {
    event.preventDefault();
    // const body = {
    //   email: this.email.current.value,
    //   password: this.password.current.value,
    //   firstName: this.firstName.current.value,
    //   lastName: this.lastName.current.value,
    // };
    // const { data } = await axios.post("api/signup", JSON.stringify(body), {
    //   headers: { "Content-Type": "application/json" },
    // });
    // firebase.auth().createUserWithEmailAndPassword -> use this on the backend
    // firebase.auth().signInWithEmailAndPassword -> use this to login
    console.log("TODO");
  };
}
