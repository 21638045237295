import React, { Component } from "react";
import { Container } from "react-bootstrap";

// import SignUp from "./SignUp";
import Login from "./Login";

export default class LoginSignUpContainer extends Component {
  render() {
    return (
      <Container>
        {/* <SignUp /> */}
        <Login />
      </Container>
    );
  }
}
