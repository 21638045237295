import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";

import { getOrder } from "./actions";
import { storage } from "../../firebase";

const mapStateToProps = ({ orders }) => ({
  orders,
});

const mapDispatchToProps = {
  getOrder,
};

class DisplayOrder extends Component {
  constructor() {
    super();
    this.state = {
      imageUrl: null,
    };
  }

  render() {
    const filteredOrder = this.props.orders.filter(
      (order) => order.id === this.props.match.params.orderId
    );
    const activeOrder = filteredOrder.length ? filteredOrder[0] : null;
    const { imageUrl } = this.state;
    return (
      <div className="display-order">
        {activeOrder && (
          <div>
            <h1>
              {activeOrder.name} from {activeOrder.restaurant.name}
            </h1>
            <p>
              By: {activeOrder.user.first_name} {activeOrder.user.last_name}
            </p>
            <h5>Ingredients:</h5>
            <ul>
              {_.map(activeOrder.ingredients, (ingredient) => {
                return <li key={ingredient.name}>{ingredient.name}</li>;
              })}
            </ul>
            <Link
              to={{
                pathname: "/create-order",
                remix: {
                  sampledFrom: activeOrder,
                  selectedRestaurant: activeOrder.restaurant.id,
                  selectedIngredients: _.map(
                    activeOrder.ingredients,
                    (ingredient) => {
                      return ingredient.id;
                    }
                  ),
                },
              }}
            >
              REMIX THIS ORDER
            </Link>
            {imageUrl && (
              <img className="order-picture" src={imageUrl} alt="Order"></img>
            )}
          </div>
        )}
      </div>
    );
  }

  async componentDidMount() {
    this.props.getOrder(this.props.match.params.orderId);
    const path = `images/${this.props.match.params.orderId}`;
    const imageUrl = await storage.ref().child(path).getDownloadURL();
    if (imageUrl) {
      this.setState({ imageUrl });
    }
  }
}

export default DisplayOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayOrder);
