import React, { Component } from "react";
import { connect } from "react-redux";

import NavBar from "./components/NavBar";
import Routes from "./Routes";
import { firebase } from "./firebase";
import { setUser } from "./actions";

import "./App.css";

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = {
  setUser,
};

class App extends Component {
  render() {
    const { user } = this.props;
    return (
      <div>
        <div id="navbar">
          <NavBar user={user} />
        </div>
        <div className="container">
          <div className="row">
            <Routes />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.setUser(user);
      }
    });
  }
}

export default App = connect(mapStateToProps, mapDispatchToProps)(App);
