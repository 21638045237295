import { SET_USER, LOGOUT_USER } from "../constants/ActionTypes";

const initialState = {};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      const { user } = action;
      // this might not always work depending on a person's name
      const [firstName, lastName] = user.displayName.split(" ");
      return {
        id: user.uid.toString(),
        email: user.email,
        firstName,
        lastName,
      };

    case LOGOUT_USER:
      return {};
    default:
      return state;
  }
}
