import firebase from "firebase";

const config = {
  apiKey: "AIzaSyBxDOCUSd2Wodzt6Z8l-sB0IHJ5JmWKEOE",
  authDomain: "remix-543dc.firebaseapp.com",
  databaseURL: "https://remix-543dc.firebaseio.com",
  projectId: "remix-543dc",
  storageBucket: "remix-543dc.appspot.com",
  messagingSenderId: "869064721112",
  appId: "1:869064721112:web:980cffd9fc667b83ce4dad",
  measurementId: "G-QLM87QJEN7",
};
firebase.initializeApp(config);
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

export { firebase, provider, storage };
