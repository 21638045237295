import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "./style.css";

const BURRITO_URL =
  "https://www.theseasonedmom.com/wp-content/uploads/2018/02/The-Easiest-Burrito-Recipe-7.jpg";
const SALAD_URL =
  "https://natashaskitchen.com/wp-content/uploads/2019/02/Greek-Salad-600x900.jpg";

class DisplayOrderCard extends Component {
  render() {
    const { order } = this.props;
    return (
      <div
        className="display-order-card"
        onClick={() => this.props.history.push(`/orders/${order.id}`)}
      >
        <img
          className="fit-picture"
          src={order.restaurant.name === "Chipotle" ? BURRITO_URL : SALAD_URL}
          alt="Burrito"
        ></img>
        <p className="order-name">{order.name}</p>
        <p className="creater-name">
          {order.user.first_name} {order.user.last_name}
        </p>
      </div>
    );
  }
}

export default withRouter(DisplayOrderCard);
