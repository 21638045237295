import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://us-central1-remix-543dc.cloudfunctions.net/app"
    : "http://localhost:8000";

export default axios.create({
  baseURL: BASE_URL,
  responseType: "json",
});
