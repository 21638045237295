import _ from "lodash";

import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";

import OrderIngredients from "./OrderIngredients";
import axios from "../../utils/axios";

import "./CreateOrder.css";

const mapStateToProps = ({ user }) => ({
  user,
});
// TODO: store creation time for the order

const mapDispatchToProps = {};

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.orderName = React.createRef();
    this.state = {
      restaurants: [],
      name: "",
      selectedRestaurant: "",
      selectedIngredients: [],
      sampledFrom: null,
      orderPhoto: null,
    };
  }

  render() {
    let remixTitle;
    if (this.state.sampledFrom !== null) {
      remixTitle = (
        <div>
          <h2>Sampled From:</h2>
          <p>
            <Link
              to={{
                pathname: "/orders/" + this.state.sampledFrom.id,
              }}
            >
              {this.state.sampledFrom.name}
            </Link>{" "}
            by {this.state.sampledFrom.user.first_name}{" "}
            {this.state.sampledFrom.user.last_name}
          </p>
        </div>
      );
    }

    const ingredientById = _.chain(this.state.restaurants || [])
      .map((restaurant) => restaurant.categories || [])
      .flatten()
      .map((category) => category.ingredients || [])
      .flatten()
      .keyBy("id")
      .value();

    return (
      <Container>
        <Row>
          <Col md="3">
            <h2 className="order-header">RESTAURANTS</h2>
            <FormGroup>
              <FormLabel>Select your restaurant:</FormLabel>
              {this.state.restaurants.map((restaurant) => (
                <Form.Check
                  key={restaurant.id}
                  type="radio"
                  label={restaurant.name}
                  checked={this.state.selectedRestaurant === restaurant.id}
                  onChange={() =>
                    this.setState({ selectedRestaurant: restaurant.id })
                  }
                />
              ))}
            </FormGroup>
          </Col>
          {this.state.selectedRestaurant && (
            <Col md="5">
              <h2 className="order-header">BUILD</h2>
              <OrderIngredients
                updateIngredients={this.updateIngredient}
                restaurant={
                  this.state.restaurants.filter(
                    (restaurant) =>
                      restaurant.id === this.state.selectedRestaurant
                  )[0]
                }
                selectedIngredients={this.state.selectedIngredients}
              />
              <div className="mb-3">
                <FormGroup>
                  <FormControl
                    name="images[]"
                    type="file"
                    multiple
                    onChange={this.handlePhotos}
                  />
                </FormGroup>
              </div>
            </Col>
          )}
          {this.state.selectedRestaurant &&
            (this.state.selectedIngredients.length || "") && (
              <Col md="4">
                <FormGroup className="remix-title">
                  {remixTitle}
                  <Form.Control
                    type="text"
                    ref={this.orderName}
                    placeholder="NAME IT!"
                  />
                </FormGroup>
                <h5>Your Ingredients:</h5>
                <ul>
                  {_.map(this.state.selectedIngredients, (ingredient) => {
                    return (
                      <li key={ingredientById[ingredient].name}>
                        {ingredientById[ingredient].name}
                      </li>
                    );
                  })}
                </ul>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.onSubmitOrder}
                >
                  Submit
                </Button>
              </Col>
            )}
        </Row>
      </Container>
    );
  }

  async componentDidMount() {
    const remix =
      this.props.location.remix !== undefined
        ? this.props.location.remix
        : {
            selectedRestaurant: "",
            selectedIngredients: [],
            sampledFrom: null,
          };

    const { data: restaurants } = await axios.get("api/create-order");
    this.setState({ restaurants });
    this.setState({ selectedRestaurant: remix.selectedRestaurant });
    this.setState({ selectedIngredients: remix.selectedIngredients });
    this.setState({ sampledFrom: remix.sampledFrom });
  }

  onSubmitOrder = async (event) => {
    event.preventDefault();
    const me = this;
    const orderName = this.orderName.current.value;
    const formData = new FormData();
    formData.append("user", me.props.user.id);
    formData.append("ingredients", this.state.selectedIngredients);
    formData.append("restaurant", this.state.selectedRestaurant);
    formData.append("name", orderName);
    formData.append("sampledFrom", this.state.sampledFrom);
    if (this.state.orderPhoto) {
      formData.append("image", this.state.orderPhoto[0]);
    }
    const { data } = await axios.post("api/create-order", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    const orderId = data.order.id;
    me.props.history.push(`/orders/${orderId}`);
  };

  updateIngredient = (ingredientId) => {
    const selectedIngredientsClone = _.cloneDeep(
      this.state.selectedIngredients
    );
    const ingredientIndex = _.indexOf(selectedIngredientsClone, ingredientId);
    if (ingredientIndex < 0) {
      selectedIngredientsClone.push(ingredientId);
      this.setState({ selectedIngredients: selectedIngredientsClone });
    } else {
      selectedIngredientsClone.splice(ingredientIndex, 1);
      this.setState({ selectedIngredients: selectedIngredientsClone });
    }
  };

  handlePhotos = (event) => {
    event.preventDefault();
    const files = event.target.files;

    if (files && files.length > 0) {
      this.setState({ orderPhoto: files });
    } else {
      console.log("no files selected");
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
);
