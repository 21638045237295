import { combineReducers } from "redux";
import orders from "./orders";
import user from "./user";

const rootReducer = combineReducers({
  orders,
  user,
});

export default rootReducer;
