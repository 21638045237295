import { SET_USER, LOGOUT_USER } from "./constants/ActionTypes";

export function setUser(user) {
  return async (dispatch) => {
    dispatch({
      type: SET_USER,
      user,
    });
  };
}

export function logoutUser() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT_USER,
    });
  };
}
