import React, { Component } from "react";
import { Form } from "react-bootstrap";
import * as _ from "lodash";

export default class OrderIngredients extends Component {
  render() {
    const { restaurant, updateIngredients } = this.props;
    return (
      <div>
        {_.map(restaurant.categories, category => {
          const fillingMax = category.max_per_order;
          return (
            <div key={category.name}>
              <h3>
                {category.name}{" "}
                {fillingMax < 10 && <span>(max: {fillingMax})</span>}
              </h3>
              <div>
                {category.ingredients.map(ingredient => {
                  return (
                    <Form.Check
                      key={ingredient.id}
                      type="checkbox"
                      label={ingredient.name}
                      checked={this.props.selectedIngredients.includes(
                        ingredient.id
                      )}
                      onChange={() => updateIngredients(ingredient.id)}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
