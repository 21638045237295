import axios from "../../utils/axios";
import { ADD_ORDER, ADD_ORDERS } from "../../constants/ActionTypes";

export function getOrders() {
  return async (dispatch) => {
    const { data } = await axios.get("api/orders");
    dispatch({
      type: ADD_ORDERS,
      orders: data.orders,
    });
  };
}

export function getOrder(orderId) {
  return async (dispatch) => {
    const { data } = await axios.get(`api/orders/${orderId}`);
    dispatch({
      type: ADD_ORDER,
      order: data.order,
    });
  };
}
