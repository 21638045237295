import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

import DisplayOrderCard from "./DisplayOrderCard";
import { getOrders } from "./actions";

const mapStateToProps = ({ orders, user }) => ({
  orders,
  user,
});

const mapDispatchToProps = {
  getOrders,
};

class DisplayAllOrders extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  render() {
    const { isLoading } = this.state;
    const { user, orders } = this.props;
    return (
      <div>
        {isLoading && <Spinner animation="border" variant="dark" />}
        {!isLoading && (
          <div>
            <h2>Top Orders</h2>
            <div>
              {orders.map((order) => {
                return <DisplayOrderCard key={order.id} order={order} />;
              })}
            </div>
          </div>
        )}
        {!isLoading && user && user.id && (
          <div>
            <h2>Created By You</h2>
            <div>
              {orders.map((order) => {
                return (
                  order.user.id === user.id && (
                    <DisplayOrderCard key={order.id} order={order} />
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  async componentDidMount() {
    await this.props.getOrders();
    this.setState({ isLoading: false });
  }
}

export default DisplayAllOrders = connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayAllOrders);
