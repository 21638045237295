import * as _ from "lodash";

import { ADD_ORDER, ADD_ORDERS } from "../constants/ActionTypes";

const initialState = [];

export default function orders(state = initialState, action) {
  switch (action.type) {
    case ADD_ORDER:
      return _.uniqBy([...state, action.order], "id");

    case ADD_ORDERS:
      return _.uniqBy([...state, ...action.orders], "id");
    default:
      return state;
  }
}
