import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

import Login from "../login/Login";
import SignUp from "../login/SignUp";

import checkbox from "../../images/check-box.png";
import cassette from "../../images/cassette.png";

import "./Homepage.css";

export default class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      signup: true,
    };
  }
  render() {
    const { signup } = this.state;

    return (
      <Row>
        <Col md={8}>
          <div className="homepage-explanations">
            <div className="homepage-checkbox-header">
              <img
                src={checkbox}
                className="homepage-checkbox"
                alt="checkbox"
              />
              <h1 className="homepage-header">CREATE</h1>
            </div>
            <h2>
              BUILD YOUR OWN UNIQUE FOOD CREATIONS AND SHARE THEM WITH THE
              WORLD.
            </h2>
            <div className="homepage-checkbox-header">
              <img
                src={checkbox}
                className="homepage-checkbox"
                alt="checkbox"
              />
              <h1 className="homepage-header">EXPLORE</h1>
            </div>
            <h2>
              FIND ITEMS THAT FULFILL YOUR CRAVINGS AND GET INSPIRED BY WHAT
              OTHERS HAVE CRAFTED.
            </h2>
            <div className="homepage-checkbox-header">
              <img
                src={checkbox}
                className="homepage-checkbox"
                alt="checkbox"
              />
              <h1 className="homepage-header">TRACK</h1>
            </div>
            <h2>
              SAVE YOUR CREATIONS DOWN SO YOU CAN ORDER THEM AGAIN AND AGAIN.
            </h2>
          </div>
        </Col>
        <Col md={4}>
          <div className="homepage-cassette-container">
            <img src={cassette} className="" alt="cassette" />
          </div>
          <div className="sign-in-sign-up-container">
            <div
              onClick={() => this.setState({ signup: true })}
              className={
                signup ? "sign-in-sign-up selected" : "sign-in-sign-up"
              }
            >
              SIGN UP
            </div>
            <div
              onClick={() => this.setState({ signup: false })}
              className={
                !signup ? "sign-in-sign-up selected" : "sign-in-sign-up"
              }
            >
              SIGN IN
            </div>
          </div>
          {signup && <SignUp />}
          {!signup && (
            <div>
              <Login />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
