import React from "react";
import { Route, Switch } from "react-router-dom";
import CreateOrder from "./components/create-order/CreateOrder";
import DisplayAllOrders from "./components/food-order-display/DisplayAllOrders";
import DisplayOrder from "./components/food-order-display/DisplayOrder";
import LoginSignUpContainer from "./components/login/LoginSignUpContainer";
import Profile from "./components/profile/Profile";
import Homepage from "./components/homepage/Homepage";

// function About() {
//   return <h2>Lani to fill in...</h2>;
// }

export default () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route
      path="/create-order"
      render={(props) => <CreateOrder {...props} />}
    />
    <Route path="/orders/:orderId" component={DisplayOrder} />
    <Route path="/explore" component={DisplayAllOrders} />
    <Route path="/login" component={LoginSignUpContainer} />
    <Route path="/profile" component={Profile} />
  </Switch>
);
